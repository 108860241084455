import axios from "../instance";
import addQuery from "../../utils/addQuery";

export default {
  searchByDate: async (body, payload) => {
    const result = await addQuery(payload);

    return axios.api
      .post(`/report/searchByDate?${result}`, body)
      .then((response) => response.data);
  },

  searchByWeek: async (body, payload) => {
    const result = await addQuery(payload);

    return axios.api
      .post(`/report/searchByWeek?${result}`, body)
      .then((response) => response.data);
  },

  searchTransByDate: async (body, payload) => {
    const result = await addQuery(payload);

    return axios.api
      .post(`/report/searchTransByDate?${result}`, body)
      .then((response) => response.data);
  },
};
