<template>
  <div v-if="loading && stateLoading">
    <Loader />
  </div>
  <div class="card" v-if="!loading && !stateLoading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        รายงานสินค้าขายดี
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light-primary btn-hover-scale"
          style="font-size: 14px; font-weight: bold; height: 40px"
          @click="gotoExportFile()"
        >
          <i style="font-size: 16px" class="bi bi-box-arrow-up"></i>
          Export
        </button>

        <DialogExportFile
          :dialogExportFile="dialogExportFile"
          @closeDialogExportFile="closeDialogExportFile"
          @selectedTypeExportFile="selectedTypeExportFile"
        />
        <!-- <button
          type="button"
          class="btn btn-sm btn-light-primary me-3"
          style="font-size: 14px; font-weight: bold"
        >
          กราฟจำนวนลูกค้า
        </button>

        <button
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          กราฟยอดขาย
        </button> -->
      </div>
    </div>
    <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <a-range-picker
          v-model:value="searchInput"
          format="DD/MM/YYYY"
          style="border-radius: 6px; height: 38px"
          inputReadOnly
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table align-middle table-hover table-row-bordered table-row-dashed gy-5"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th class="text-center">รหัสสินค้า</th>
              <th class="text-center">ชื่อสินค้า</th>
              <th class="text-center">ราคาขาย</th>
              <th class="text-center">จำนวนขาย</th>
              <th class="text-center">รวมยอดขาย</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataShow.length < 1">
              <td class="text-center" colspan="11">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataShow" :key="index">
              <td>{{ item.product_code ? item.product_code : "-" }}</td>
              <td class="fw-boldest">
                {{ item.product_name }}
              </td>
              <td class="text-end">{{ formatPrice(item.item_price) }}</td>
              <td class="text-end">{{ formatPrice(item.item_amt) }}</td>
              <td class="text-end">{{ formatPrice(item.total) }}</td>
              <td class="text-center">
                <button
                  :class="
                    currentPage > 1
                      ? class4
                      : index <= 2
                      ? class1
                      : index >= 3 && index <= 5
                      ? class2
                      : index >= 6 && index <= 8
                      ? class3
                      : class4
                  "
                  style="padding: 5px; width: 50px; cursor: default"
                >
                  #{{ item.no }}
                </button>
              </td>
              <td>
                <a class="btn btn-light text-muted fw-boldest btn-sm">+</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="isExcel">
      <ExportExcelFile :dataExportExcel="dataExportExcel" />
    </div>

    <div v-if="isPdf">
      <ExportPdfFile :dataExportPdf="dataExportPdf" />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

import reportApi from "@/api/report/";

import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

import { filterReport3 } from "@/utils/reports/report3/filterReport3";

import DialogExportFile from "./components/dialogExportFile.vue";
import ExportExcelFile from "./components/exportFileExcel.vue";
import ExportPdfFile from "./components/exportFilePdf.vue";

import { Mutations } from "@/store/enums/StoreEnums";
import { mapState } from "vuex";

export default {
  components: {
    DialogExportFile,
    ExportExcelFile,
    ExportPdfFile,
    Pagination,
    Search,
    Loader,
  },
  setup() {
    document.title = "BELUCA | รายงานสินค้าขายดี";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataShow: [],
    companyItems: [],

    showing1: null,

    dialogConfirmByPass: false,

    tableItems: [],
    itemApprove: {},

    searchInput: [],
    allData: [],

    dataTable: [],

    pdItems: [],

    dateFormat: "YYYY/MM/DD",

    class1: "btn btn-sm btn-light-success fw-boldest",
    class2: "btn btn-sm btn-light-info fw-boldest",
    class3: "btn btn-sm btn-light-warning fw-boldest",
    class4: "btn btn-sm btn-light fw-boldest",

    currentPage: "",

    //export
    dialogImportExcel: false,

    dialogExportFile: false,

    dataExportExcel: [],
    isExcel: false,

    dataExportPdf: [],
    isPdf: false,

    isExport: false,
    loadingExport: false,
    indexExport: "",

    loadingExport1: false,
  }),

  computed: {
    ...mapState({
      stateLoading: (state) => state.MockItems.isLoading,
    }),
  },

  async created() {
    this.setDateToday();
    await this.getPd();
    await this.search();
  },

  watch: {
    $route(to, from) {
      this.currentPage = to.query.page;
    },
  },

  methods: {
    setDateToday() {
      this.searchInput[0] = dayjs().startOf("month");
      this.searchInput[1] = dayjs(new Date());
    },

    async getPd() {
      this.loading = true;
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      try {
        responseData = await whApi.product.getAll({
          is_cancel: "0",
          companyId: companyId,
          companyBranchId: companyBranchId,
        });
      } catch (error) {
        console.log(error);
      }

      if (responseData.response_status === "SUCCESS") {
        this.pdItems = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER.",
        });
      }
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    async search() {
      // this.reportDate = dataReport1();
      if (this.searchInput) {
        const companyId = localStorage.getItem("companyId");
        const companyBranchId = localStorage.getItem("companyBranchId");
        this.loading = true;
        this.$store.commit(Mutations.SET_LOADING, true);

        const newDateStart = moment(this.searchInput[0].$d).format(
          "YYYY-MM-DD"
        );
        const newDateEnd = moment(this.searchInput[1].$d)
          .add(1, "days")
          .format("YYYY-MM-DD");

        const responseSearch = await reportApi.sale.searchByDate(
          {
            dateStart: newDateStart,
            dateEnd: newDateEnd,
          },
          {
            companyId: companyId,
            companyBranchId: companyBranchId,
          }
        );

        if (responseSearch.response_status === "SUCCESS") {
          this.tableItems = responseSearch.data;

          const filterReport = await filterReport3(
            this.pdItems,
            responseSearch.data
          );

          this.filterBestSeller(filterReport);

          this.loading = false;
          setTimeout(() => {
            this.$store.commit(Mutations.SET_LOADING, false);
          }, 500);
        } else {
          this.loading = false;
          setTimeout(() => {
            this.$store.commit(Mutations.SET_LOADING, false);
          }, 500);
          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER.",
          });
        }
      }
    },

    // sum best seller + product
    filterBestSeller(items) {
      let { newPd, pdBestSellerByDate } = items;

      newPd.forEach((element, index) => {
        pdBestSellerByDate.forEach((element2, index2) => {
          if (element2.product_id === element.product_id) {
            newPd.splice(index, 1, { ...element2, no: index2 });
          }
        });
      });

      newPd.sort((a, b) => b.total - a.total);

      newPd.forEach((element, index) => {
        newPd[index] = { ...newPd[index], no: index + 1 };
      });

      this.dataShow = newPd;
      this.dataItemsShow = newPd;
      this.tableItems = newPd;

      this.dataExportExcel = newPd;
      this.dataExportPdf = newPd;
    },

    //export file excel, pdf
    gotoExportFile() {
      this.dialogExportFile = true;
      this.isExcel = false;
      this.isPdf = false;
    },

    closeDialogExportFile() {
      this.dialogExportFile = false;
    },

    selectedTypeExportFile(val) {
      this.dialogExportFile = false;
      if (val === "excel") {
        this.isExcel = true;
      } else {
        this.isPdf = true;
      }
    },

    //export file excel, pdf
  },
};
</script>

<style scoped src="@/assets/custom-outside.css"></style>
