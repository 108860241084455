<template></template>

<script>
import * as XLSX from "xlsx";

import whApi from "@/api/warehouse/";

import dayjs, { Dayjs } from "dayjs";

import numbro from "numbro";

export default {
  name: "app",
  props: {
    dataExportExcel: Object,
  },
  data() {
    return {
      jsonExport: [],

      mainProduct: [],
      subProduct: [],

      // loading: false
    };
  },

  async created() {
    // await this.getAllMainPd();
    // await this.getAllSubPd();
    await this.groupDateExport();
    // this.onExport();
  },

  methods: {
    groupDateExport() {
      this.dataExportExcel.forEach((element, index) => {
        this.jsonExport.push({
          รหัสสินค้า: element.product_code,
          ชื่อสินค้า: element.product_name,
          ราคาขาย: numbro(element.item_price).format({
            thousandSeparated: true,
          }),
          จำนวนขาย: numbro(element.item_amt).format({
            thousandSeparated: true,
            mantissa: 2,
          }),
          รวมยอดขาย: numbro(element.total).format({
            thousandSeparated: true,
          }),
          "": "#" + element.no,
        });
      });

      this.onExport();
    },

    async getAllMainPd() {
      // this.loading = true;
      let responseData = {};
      try {
        responseData = await whApi.mainProduct.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.mainProduct = responseData.data;
        // this.loading = false;
      }
    },

    async getAllSubPd() {
      // this.loading = true;
      let responseData = {};
      try {
        responseData = await whApi.subProduct.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.subProduct = responseData.data;
        // this.loading = false;
      }
    },

    onExport() {
      const date = dayjs(new Date()).format("YYYY-MM-DD-HHMM");

      const dataWS = XLSX.utils.json_to_sheet(this.jsonExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS, "BestSeller1", true);
      XLSX.writeFile(wb, `BestSeller-${date}.xlsx`);
    },
  },
};
</script>
