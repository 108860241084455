<template>
  <div></div>
</template>

<script>
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "../../../../assets/build/vfs_fonts";

import apiConfig from "../../../../config/api";
import whApi from "@/api/warehouse/";

import moment from "moment";

import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import { toFixed } from "ant-design-vue/lib/input-number/src/utils/MiniDecimal";

import { ArabicNumberToText } from "../../../../utils/numberToText";

import JsBarcode from "jsbarcode/bin/JsBarcode";

import numbro from "numbro";

export default {
  props: {
    dataExportPdf: Object,
  },
  data: () => ({
    jsonExport: [],

    newDataTable: [],

    user: "",
    numberToTh: "",
  }),
  async created() {
    console.log(this.dataExportPdf, 123);
    // await this.getAllMainPd();
    // await this.getAllSubPd();
    await this.groupDateExport();
  },

  methods: {
    groupDateExport() {
      this.setDataToTable();
    },

    async getAllMainPd() {
      // this.loading = true;
      let responseData = {};
      try {
        responseData = await whApi.mainProduct.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.mainProduct = responseData.data;
        // this.loading = false;
      }
    },

    async getAllSubPd() {
      // this.loading = true;
      let responseData = {};
      try {
        responseData = await whApi.subProduct.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.subProduct = responseData.data;
        // this.loading = false;
      }
    },

    exports() {
      const newWindow = window.open("", "_blank");

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: "Kanit-Regular.ttf",
          bold: "Kanit-Medium.ttf",
          italics: "Kanit-Medium.ttf",
          bolditalics: "Kanit-Medium.ttf",
        },
      };

      const html = [
        {
          text: "รายงานสินค้าขายดี",
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 10],
        },

        // TABLE
        {
          table: {
            // width: "100%",
            widths: ["*", "*", "*", "*", "*", "*"],
            body: this.newDataTable,
          },
        },
      ];

      const pdfData = {
        content: html,
        pageOrientation: "landscape",
      };
      pdfMake.createPdf(pdfData).open({}, newWindow);
      this.$emit("generatedFile");
    },

    setDataToTable() {
      this.dataExportPdf.forEach((element, index) => {
        if (index === 0) {
          this.newDataTable.push(
            [
              {
                fontSize: 9,
                text: "รหัสสินค้า",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "ชื่อสินค้า",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "ราคาขาย",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "จำนวนขาย",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "รวมยอดขาย",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "",
                alignment: "center",
              },
            ],
            [
              {
                text: element.product_code,
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "left",
              },
              {
                text: element.product_name,
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "left",
              },
              {
                text: numbro(element.item_price).format({
                  thousandSeparated: true,
                }),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "right",
              },
              {
                text: numbro(element.item_amt).format({
                  thousandSeparated: true,
                  mantissa: 2,
                }),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "right",
              },
              {
                text: numbro(element.total).format({
                  thousandSeparated: true,
                  mantissa: 2,
                }),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "right",
              },
              {
                text: "#" + element.no,
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "center",
              },
            ]
          );
        } else {
          this.newDataTable.push([
            {
              text: element.product_code,
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "left",
            },
            {
              text: element.product_name,
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "left",
            },
            {
              text: numbro(element.item_price).format({
                thousandSeparated: true,
              }),
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: numbro(element.item_amt).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: numbro(element.total).format({
                thousandSeparated: true,
                mantissa: 2,
              }),
              fontSize: 9,
              fillColor: "#eaeced",
              alignment: "right",
            },
            {
              text: "#" + element.no,
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "center",
            },
          ]);
        }
      });

      this.exports();
    },
  },
};
</script>
