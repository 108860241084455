import axios from "../instance";
const companyId = localStorage.getItem("companyId");
import addQuery from "../../utils/addQuery";

export default {
  searchPdByDate: async (body, payload) => {
    const result = await addQuery(payload);

    return axios.api
      .post(`/report/searchPdByDate?${result}`, body)
      .then((response) => response.data);
  },

  searchPdByUpdate: async (body, payload) => {
    const result = await addQuery(payload);

    return axios.api
      .post(`/report/searchPdByUpdate?${result}`, body)
      .then((response) => response.data);
  },

  searchWhSnapshot: async (body, payload) => {
    const result = await addQuery(payload);

    return axios.api
      .post(`/snapshot/search?${result}`, body)
      .then((response) => response.data);
  },

  searchTwelveWhSnapshot: async (body, payload) => {
    const result = await addQuery(payload);

    return axios.api
      .post(`/snapshot/searchTwelve?${result}`, body)
      .then((response) => response.data);
  },

  searchWhSnapshotByMovement: async (body, payload) => {
    const result = await addQuery(payload);

    return axios.api
      .post(`/snapshot/searchByMovement?${result}`, body)
      .then((response) => response.data);
  },
};
